import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { useState } from "react";
/* Image Imports */
import logo from "app-images/logo.svg";
import arrow from "app-images/arrow.svg"
import reset from "app-images/reset.svg"
/* Component Imports */
import SuccessLabel from '../app-components/SuccessLabel.js';
import ErrorLabel from '../app-components/ErrorLabel.js';
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
import ReCAPTCHA from "react-google-recaptcha";

/* All of the element stylings */
const Container = tw.div`relative min-h-screen bg-gray-500 text-white font-medium flex justify-center items-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center items-center flex-1`;
const MainContainer = tw.div`w-full md:w-8/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto mb-2`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-2`;
const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm text-secondary-500 focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon { ${tw`w-6 h-6 -ml-2`} }
  .text { ${tw`ml-3`} } `;
const ExitImage = tw.img`max-w-xs h-5 mt-4`;
const SubHeading = tw.p`w-full text-sm font-semibold text-center mt-2 mb-1 text-secondary-500`
const ResetIcon = tw.img`max-w-xs h-6`

export default () => {
  const [email, setEmail] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  let submitForm = (e) => {
    setShowEmailError(false);
    setShowError(false);
    setShowSuccess(false);
    e.preventDefault();
    const regex = /^[A-Za-z0-9]+[._]?[a-z0-9]+[@]\w+[.]\w{2,3}$/g;
    let match = email.match(regex);
    if (email === "" || captchaToken === "") { return };
    if (!match) { setShowEmailError(true) };

    setLoading(true);
    let form = new FormData();
    form.append('email', email);
    form.append("client_token", captchaToken);

    fetch("https://api.trackjobs4.me/user/request_password_reset", {
      method : "POST",
      body : form,
    })
    .then((response) => {
      setLoading(false);
      if (response.ok || response.status === 404) { setShowSuccess(true) } 
      else { setShowError(true) }
    })
    .catch((error) => {
      setLoading(false);
      setShowError(true);
      console.log(error);
    })
  };

  return (
    <LoadingOverlay 
    active = {loading}
    spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
    styles={{
        overlay: (base) => ({
          ...base,
          backgroundColor: 'currentColor'
        })
      }}
    >
      <AnimationRevealPage>
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href="/">
                <LogoImage src={logo} />
                <Heading>TRACKJOBS4.ME</Heading>
              </LogoLink>
              <MainContent>
                  <SubHeading>Forgot your password? It's all good, we've got you covered.</SubHeading>
                  <SubHeading>Just enter your email below and if we have an account on file, we'll send a password recovery message to your inbox!</SubHeading>
                  {/* Error Labels */}
                  <ErrorLabel visibility={showError} setVisibility={setShowError}/>
                  <ErrorLabel visibility={showEmailError} setVisibility={setShowEmailError} message={"Please enter a valid email address."} title={"Email Error"}/>
                  <SuccessLabel visibility={showSuccess} setVisibility={setShowSuccess} message={"Your reset request processed successfully. \n You should receive an email with instructions to reset in the next few minutes."} />
                <FormContainer>
                  <Form>
                    <Input type="email" name="email" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value) } }/>
                    <div tw="flex flex-row items-center justify-center mt-4">  
                      <ReCAPTCHA
                        sitekey="6LcpLc0ZAAAAAI0uPxuTe7IMBYPVG05KRalGk4uX"
                        onChange={token => setCaptchaToken(token)}
                        onExpired={e => setCaptchaToken("")}
                      />
                    </div>
                    <SubmitButton type="submit" onClick={submitForm}>
                      <ResetIcon src={reset} alt="" />
                      <span className="text">Reset My Password</span>
                    </SubmitButton>
                  </Form>
                </FormContainer>
                <LogoLink href="/"><ExitImage src={arrow} alt=""/></LogoLink>
              </MainContent>
            </MainContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
    </LoadingOverlay>
  );
}
