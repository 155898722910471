import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Nav from "app-components/Nav.js";
import Footer from "app-components/Footer.js";
import JobsOverview from "app-components/Dashboard/JobsOverview.js";
import JobApplications from "app-components/Dashboard/JobApplications.js"
import { useState } from "react";

import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';

export default () => {
  /* Use this Intermediary value to communicate between two sibling components */
  const [jobsIntermediaryValue, setJobsIntermediaryValue] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  return (
    <AnimationRevealPage>
    <Nav/>

      <LoadingOverlay 
      active = {pageLoading}
      spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
      styles={{
          overlay: (base) => ({
            ...base,
            backgroundColor: 'currentColor'
          })
        }}
      >
      <JobsOverview jobsIntermediaryValue={jobsIntermediaryValue}></JobsOverview>
      <JobApplications setJobsIntermediaryValue={setJobsIntermediaryValue} setPageLoading={setPageLoading}></JobApplications>
    </LoadingOverlay>

    <Footer />
    </AnimationRevealPage>
  );
};



