import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';

/* Modal stuff */
const ModalSelector = tw.select`bg-gray-300 border rounded-lg py-2 px-2 mb-2 h-12 w-full appearance-none`;
const ModalContainer = tw.div`flex flex-col items-center justify-center w-full p-5 text-secondary-500 overflow-scroll`;
const ModalTitle = tw.h1`font-bold tracking-wide text-center text-lg mb-1`;
const ModalRow = tw.div`flex flex-col md:flex-row items-stretch justify-center w-full text-sm`;
const ModalLabel = tw.label`uppercase tracking-wide text-gray-700 font-bold mb-1`;
const ModalTextArea = tw.textarea`w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-2`
const ModalButtonsContainer = tw.div`flex flex-row items-center justify-end w-full mt-2`;
const ModalCancelButton = tw.button`bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-lg shadow mx-1`;
const ModalSaveButton = tw.button`bg-orange-500 hover:bg-orange-700 text-white font-semibold py-2 px-4 border border-orange-500 rounded-lg mx-1 shadow`;
const ModalInput = tw.input`w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-2`

/* Pass down the properties to this component */
export default ({
    CloseModal = null,
    fetchAgain = null
}) => {
    const [currDateTime] = useState(new Date().toLocaleString());
    const [companyName, setCompanyName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [URL, setURL] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('active'); 
    const [loading, setLoading] = useState(false);

    /* Job gets its row ID at the server */
    let addNewEntry = () => {
        setLoading(true);
        let data = {
            'date' : currDateTime,
            'company' : companyName,
            'title' : jobTitle,
            'url' : URL,
            'notes' : notes,
            'status' : status
        };

        let data_json = JSON.stringify(data);

        return fetch("https://api.trackjobs4.me/user/data/add_entry", {
            method : "POST",
            credentials : "include",
            headers : {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json'
            },
            body : data_json
        })
        .then(response => {
            if (response.ok) {
                fetchAgain(); /* This is needed because the server generates row ids*/
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                CloseModal();
            }
        })
        .catch( (error) => {
            setLoading(false);
            console.log(error)
        })
    }

    return (
        <LoadingOverlay 
        active = {loading}
        spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
        styles={{
            overlay: (base) => ({
              ...base,
              backgroundColor: 'currentColor'
            })
          }}
        >
            <ModalContainer>
                <ModalRow tw="border-b mb-2 font-extrabold">
                    <ModalTitle>Add New Job</ModalTitle>
                </ModalRow>

                <ModalRow>
                    <div tw="w-full md:w-5/12 mx-1">
                        <div tw="flex flex-col items-start">
                            <ModalLabel>Company Name</ModalLabel>
                            <ModalInput placeholder="Charles Schwab" maxLength="50" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                        </div>
                    </div>
                    <div tw="w-full md:w-7/12 mx-1">
                        <div tw="flex flex-col items-start">
                            <ModalLabel>Job Title</ModalLabel>
                            <ModalInput placeholder="Software Engineer" maxLength="180" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)}/>
                        </div>
                    </div>
                </ModalRow>

                <ModalRow>
                    <div tw="w-full md:w-3/4 mx-1">
                        <div tw="flex flex-col items-start">
                            <ModalLabel>URL</ModalLabel>
                            <ModalTextArea placeholder="http://" value={URL} onChange={(e) => setURL(e.target.value)} />
                        </div>
                    </div>

                    <div tw="w-full md:w-1/4 mx-1">
                        <div tw="flex flex-col items-start">
                            <ModalLabel>Status</ModalLabel>
                            <ModalSelector value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="active" >Active</option>
                                <option value="inactive">Inactive</option>
                                <option value="phone-screen">Phone Screen</option>
                                <option value="interview">Interview</option>
                                <option value="onsite">Onsite</option>
                                <option value="offer">Offer</option>
                                <option value="ongoing">Ongoing</option>
                                <option value="stale">Stale</option>
                            </ModalSelector>                    
                        </div>
                    </div>
                </ModalRow>

                <ModalRow>
                    <div tw="w-full mx-1">
                        <div tw="flex flex-col items-start">
                            <ModalLabel>Notes</ModalLabel>
                            <ModalTextArea placeholder="Seems like a great fit!" value={notes} onChange={(e) => setNotes(e.target.value)} />
                        </div>
                    </div>
                </ModalRow>

                <ModalButtonsContainer>
                    <ModalCancelButton onClick={CloseModal}>Cancel</ModalCancelButton>
                    <ModalSaveButton onClick={addNewEntry}>Add</ModalSaveButton>
                </ModalButtonsContainer>

            </ModalContainer>
        </LoadingOverlay>
    );
};
