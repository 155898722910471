import React from "react";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

export default ({
    Component = null,
    returnNullOnChange = false,
}) => {
    // const [loading, setLoading] = useState(true);
    const [authStatus, setAuthStatus] = useState(false);
    const [authIsLoading, setAuthIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://api.trackjobs4.me/user/detect_session', {
            method : "GET",
            credentials : "include"
        })
        .then((response) => {
            if (response.ok) {
                setAuthStatus(true);
                setAuthIsLoading(false);
            } else {
                setAuthIsLoading(false);
            }
        })
        .catch((error) => {
            setAuthIsLoading(false);
        })
    }, []);

    if (authIsLoading) {
        return null
    } else {
        if (authStatus === false) {
            return <Component />
        } else {
            if (returnNullOnChange === true) {
                return null 
            } else {
                return <Redirect to="/" />
            }
        }
    }

}