import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import temp from "../../app-images/demo-2.gif"
import LazyLoad from 'react-lazy-load';

const Container = tw.div`relative`;
const Content = tw.div`flex flex-col items-center mx-auto my-8 md:my-16`;
const TwoColumn = tw.div`flex flex-col xl:flex-row justify-around items-center mt-8 md:mt-0`;
const ImageColumn = tw.div`mb-4 xl:w-6/12 md:mb-0`;
const TextColumn = tw.div`md:order-last`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Heading = tw.h2`text-4xl lg:text-5xl font-black tracking-wide text-center mb-4`
const Steps = tw.ul``;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center justify-center xl:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400 md:w-2/12`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6 md:w-6/12`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default (
  {
  heading = (<>Easily Integrates Into Your <span tw="text-primary-500">Job Search.</span></>),
  imageSrc = temp,
  steps = null,
  }
) => {
  steps = [
    {
      heading: "Register",
      description: "Create an account with us, you only need to provide a name and an email!"
    },
    {
      heading: "Download",
      description: "Get the extension for Chrome or Firefox."
    },
    {
      heading: "Begin",
      description: "Toggle the extension anytime you're on a job posting, and hit the add button. Review the information the extension was able to gather for accuracy, and make changes if needed."
    },
    {
      heading: "Enjoy",
      description: "Click submit to add the job to your profile. Enjoy having a history of all your job applications in one place!"
    }
  ];

  return (
    <Container id="Usage">
        <hr tw="mt-4"></hr>
        <Content>
          <TwoColumn>
            <ImageColumn>
            <Heading>{heading}</Heading>
            <LazyLoad offset={800} debounce={false} onContentVisible={() => console.log('Demo GIF has been lazy loaded.')}> 
              <img src={imageSrc} tw="max-w-xs md:max-w-xl m-1 mx-auto rounded-lg shadow-lg md:shadow-2xl mb-6" alt=""/>
              </LazyLoad>
            </ImageColumn>
            <TextColumn>
              <TextContent>
                <p tw="text-center text-base lg:text-lg mb-2">Add the tool to your job search workflow in a few simple steps</p>
                <Steps>
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                      <StepText>
                        <StepHeading>{step.heading}</StepHeading>
                        <StepDescription>{step.description}</StepDescription>
                      </StepText>
                    </Step>
                  ))}
                </Steps>
              </TextContent>
            </TextColumn>
          </TwoColumn>
        </Content>
    </Container>
  );
};
