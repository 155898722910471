import React from "react";
import Error from "./ErrorPage.js"
import { useState, useEffect } from "react";

export default ({
    Component = null,
}) => {
    // const [loading, setLoading] = useState(true);
    const [authStatus, setAuthStatus] = useState(false);
    const [authIsLoading, setAuthIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://api.trackjobs4.me/user/detect_session', {
            method : "GET",
            credentials : "include"
        })
        .then((response) => {
            if (response.ok) {
                setAuthStatus(true);
                setAuthIsLoading(false);
            } else {
                setAuthIsLoading(false);
            }
        })
        .catch((error) => {
            setAuthIsLoading(false);
        })
    }, []);

    if (authIsLoading) {
        return null
    } else {
        if (authStatus === true) {
            return <Component />
        } else {
            return (
                <Error 
                    title={"401. THIS IS AN ERROR."}
                    message={"You need to be signed in to view this page. \n Please sign in or create an account & try again!"}
                />
            )
        }
    }

}