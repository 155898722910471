import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
/* Misc. Imports */
import useAnimatedNavToggler from "../helpers/useAnimatedNavToggler.js";
import logo from "../app-images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import AnchorLink from 'react-anchor-link-smooth-scroll'

/* The below code is for generating dynamic break points for navbar. Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros */
const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};

/* Tailwind styled elements ... Also - hocus: stands for "on hover or focus" */
const Header = tw.header`flex justify-between items-center mx-auto`;
export const NavLinks = tw.div`inline-block`;
export const NavLink = tw.a`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`;
export const PrimaryLink = tw(NavLink)`lg:mx-0 px-8 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline border-b-0`;
export const LogoLink = styled(NavLink)`${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img { ${tw`w-10 mr-3`} }`;
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300`;
export const MobileNavLinks = motion.custom(styled.div`${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} { ${tw`flex flex-col items-center`} }`);
export const DesktopNavLinks = tw.nav`hidden lg:flex flex-1 justify-between items-center`;

/* Navbar component itself */
export default ({ 
    roundedHeaderButton = false, 
    logoLink, 
    links, 
    className, 
    collapseBreakpointClass = "lg" 
}) => {
  const [auth, setAuth] = useState(false);
  const history = useHistory();

  /* Determine Nav State on any render through UseState() hook */
  useEffect(() => {
    fetch('https://api.trackjobs4.me/user/detect_session', {
      method : "GET",
      credentials : "include"
    })
    .then((response) => { if (response.ok) { setAuth(true) } })
    .catch((error) => { console.log(error) });
  }, []);

  /* Hit API to clear session when a user clicks logout */
  let logoutUser = (e) => {
    e.preventDefault(); /* Not sure why I need this but the fetch request kept failing until this was enabled ???? */
    fetch('https://api.trackjobs4.me/user/sign_out', {
      method : "POST",
      credentials : "include"
   }) /* Set auth false below in case user logs out on homepage */
   .then(response => { 
     if (response.ok) { 
      if (window.location.pathname !== "/") {
        history.push("/") 
      }
      window.location.reload();
    } 
  })
   .catch((error) => { console.log(error); })
  };

  let detectBrowser = () => {
    let isFirefox = typeof InstallTrigger !== 'undefined';
    let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      alert("Looks like you're using a mobile device. To download the browser extension, please visit this site on a desktop computer!");
      return;
     }

    if (isFirefox === true) {
      window.open("https://addons.mozilla.org/en-US/firefox/addon/trackjobs4-me/");
    } else if (isChrome === true) {
      window.open("https://chrome.google.com/webstore/detail/trackjobs4me/fdepaacddefkhpkkebcphgjmdobpmhcm");
    } else {
      alert("Looks like you're using an incompatible browser. Please try this again in either Google Chrome or Mozilla Firefox!");
      return;
    }
  }

  /* Navbar items for a logged in client */
  const loggedInLinks = [
    <NavLinks key={1}>
      <NavLink href="/my-jobs">My Jobs</NavLink>
      <NavLink href="/my-account">My Account</NavLink>
      <NavLink href="/submit-feedback">Feedback</NavLink>
      <NavLink onClick={detectBrowser} role="button">Download</NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href="/" onClick={logoutUser}>Sign Out</PrimaryLink>
    </NavLinks>
  ];

  /* Navbar items for a logged out client */
  const loggedOutLinks = [
    <NavLinks key={1}>
      <NavLink onClick={detectBrowser} role="button">Download</NavLink>
      <AnchorLink tw="text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500" href="#Overview">Overview</AnchorLink>
      <AnchorLink tw="text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500" href="#Usage">Usage</AnchorLink>
      <NavLink href="/sign-in">Sign In</NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href="/sign-up">Get Started</PrimaryLink>
    </NavLinks>
  ];

  /* Misc. variables */
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];
  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
      TRACKJOBS4.ME
    </LogoLink>
  );

  /* Assign according to auth state from API */
  logoLink = defaultLogoLink;
  if (auth === true) { links = loggedInLinks } 
  else { links = loggedOutLinks };

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>
      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks} onClick={toggleNavbar}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};