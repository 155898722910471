import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useState, useEffect } from "react";


/* Import images */
import company from "../../app-images/company.svg";
import resume from "../../app-images/resume.svg";
import growth from "../../app-images/growth.svg";
import calendar from "../../app-images/calendar.svg";
import FadeIn from "react-fade-in";

/* Setup the styling using tailwind macro */
const Container = tw.div`relative`;
const Content = tw.div`mx-auto my-8`;
const Heading = tw.h1`w-full text-center text-2xl lg:text-left lg:text-3xl font-light my-3`;
const OverviewContainer = tw.div`flex flex-col lg:flex-row items-center justify-around`
const OverviewItem = tw.div`flex flex-row items-center justify-center shadow-lg p-4 my-2 lg:my-0 w-full lg:w-auto`
const OverviewImage = tw.img`max-w-xs h-16 mr-3`
const OverviewContent = tw.div`flex flex-col items-center justify-center`
const OverviewMetric = tw.h1`font-extrabold text-4xl w-full text-center`
const OverviewTitle = tw.h2`text-sm font-bold tracking-tight text-center`

/* Pass down the properties to this features component */
export default ({
  jobsIntermediaryValue = null
}) => {
    /* Utilize the useState() hook to assign a value to something for our component */
    const [jobs, setJobs] = ((jobsIntermediaryValue === null) ? useState([]) : useState(jobsIntermediaryValue));
    const [name, setName] = useState("")
    const [totalJobs, setTotalJobs] = useState(0);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [totalActive, setTotalActive] = useState(0);
    const [totalDays, setTotalDays] = useState(0);

    /* Intermediary Value is a state that's passed down from the parent component. It allows the 
    overview metrics to be updated in real time when a user adds/deletes/updates jobs */
    useEffect(() => {
      setJobs(jobsIntermediaryValue);
    }, [jobsIntermediaryValue, setJobs])
  
    /* This hook runs onMount() only to get user info*/
    useEffect(() => {
        fetch('https://api.trackjobs4.me/user/detect_session', {
            method : "GET",
            credentials : "include"
        })
        .then(response => response.json())
        .then((result) => {
            setName(result['NAME']);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    /* This hook runs anytime jobs local state changes, to get user job data and 
    calculate metrics.. this is what allows for the real-time updates */
    useEffect(() => {
      /* Metric 1 */
      setTotalJobs( (jobs !== null) ? jobs.length : 0);

      /* Metric 2 */
      setTotalCompanies(() => {
        try {
          let uniqueValues = new Set();
          jobs.forEach(job => {
            let temp = job['company'].trim();
            uniqueValues.add(temp); 
          });
          return uniqueValues.size;
        } catch (error) {
          console.log(error);
          return 0;
        }
      });

      /* Metric 3 */
      setTotalActive(() => {
        try {
          let count = 0;
          jobs.forEach(job => {
            let status = job['status'];
            if (status !== 'inactive' && status !== 'stale') {
              count += 1;
            }
          });
          return count;
        } catch (error) {
          console.log(error);
          return 0;
        }
      });

      /* Metric 4 */
      setTotalDays(() => {
        /* Metric 4 inner method */
        let computeAge = (DateAndTime) => {
          try {
              let oldDate = new Date(DateAndTime);
              let currDate = new Date();
              let diff = currDate.getTime() - oldDate.getTime();
              let days = diff / (1000*3600*24)
              return days.toFixed()
          } catch (error) {
              console.log(error);
              return 0;
          }
        }
        /* Actual Implementation */
        try {
          let min = Number.MAX_SAFE_INTEGER;
          jobs.forEach(job => {
            let age = parseInt(computeAge(job.date));
            if (age < min) { min = age };
          });
          if (min === Number.MAX_SAFE_INTEGER) { return 0 }
          return min;
        } catch (error) {
          console.log(error);
          return 0;
        }
      });
    }, [jobs]);

  return (
    <Container id="Overview">
      <hr tw="mt-4"></hr>
      <Heading>Hi {name === "" ? "User" : name}! Here's a quick look at your profile</Heading>
      <FadeIn transitionDuration={2500}>
        <Content>
          <OverviewContainer>
              {/* Metric 1 */}
              <OverviewItem>
                  <OverviewImage src={resume} alt=""/>
                  <OverviewContent>
                      <OverviewTitle>Total Applications</OverviewTitle>
                      <OverviewMetric>{totalJobs}</OverviewMetric>
                  </OverviewContent>
              </OverviewItem>

              {/* Metric 2 */}
              <OverviewItem>
                  <OverviewImage src={company} alt=""/>
                  <OverviewContent>
                      <OverviewTitle>Companies Applied</OverviewTitle>
                      <OverviewMetric>{totalCompanies}</OverviewMetric>
                  </OverviewContent>
              </OverviewItem>

              {/* Metric 3 */}
              <OverviewItem>
                  <OverviewImage src={growth} alt=""/>
                  <OverviewContent>
                      <OverviewTitle>Active Applications</OverviewTitle>
                      <OverviewMetric>{totalActive}</OverviewMetric>
                  </OverviewContent>
              </OverviewItem>

              {/* Metric 4 */}
              <OverviewItem>
                  <OverviewImage src={calendar} alt=""/>
                  <OverviewContent>
                      <OverviewTitle>Days Since Last Application</OverviewTitle>
                      <OverviewMetric>{totalDays}</OverviewMetric>
                  </OverviewContent>
              </OverviewItem>
          </OverviewContainer>
        </Content>
      </FadeIn>
    </Container>
  );
};
