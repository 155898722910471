import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useState } from "react";
import { useHistory } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
import ErrorLabel from '../ErrorLabel.js';
import ReCAPTCHA from "react-google-recaptcha";
import LazyLoad from 'react-lazy-load';

/* Styled elements */
const Container = tw.div`relative`;
const Content = tw.div`p-10 sm:p-12 md:p-16 rounded-lg relative mx-auto my-8 md:my-16`;
const TwoColumn = tw.div`flex flex-col items-center lg:flex-row justify-around lg:items-stretch`;
const LeftColumn = tw.div`flex flex-col mb-5 lg:mb-0 items-center justify-center text-center`;
const RightColumn = tw.div`flex flex-col justify-center w-full md:w-7/12`;
const Input = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-xs md:text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0 text-secondary-500`;
const SubmitButton = tw.button` w-full mt-4 py-4 px-2 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const UserCheck = tw.p`font-semibold text-sm my-2 text-center`;

export default () => { 
  /* Form states so I don't have to use document.getElementById, this is best practice for react functional components */
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  
  /* Error handling state management */
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showUserAlreadyExistsError, setshowUserAlreadyExistsError] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const history = useHistory();

  let formHandler = (e) => {
    /* Clear any active errors */
    setShowEmailError(false);
    setShowPasswordError(false);
    setshowUserAlreadyExistsError(false);
    setShowDefaultError(false);
    
    /* Prevent any default form submissions */
    e.preventDefault(); 
    const inputName = name;
    const inputEmail = email.toLowerCase();
    const inputPassword = password;
    const regex = /^[A-Za-z0-9]+[._]?[a-z0-9]+[@]\w+[.]\w{2,3}$/g;
    let match = inputEmail.match(regex);
    if (inputName === "" || inputEmail === "" || inputPassword === "" || captchaToken === "") { return }
    if (!match) { setShowEmailError(true); return }
    if (inputPassword.length < 6) { setShowPasswordError(true); return }
    /* In all other cases, pass off to make API request */
    handleCreateAccount();
  };
  
  /* Get form data and make fetch() request */
  let submitFormData = () => {
      setLoading(true);
      let form = new FormData();
      form.append("name", name)
      form.append("email", email.toLowerCase());
      form.append("password", password);
      form.append("client_token", captchaToken);
      return fetch("https://api.trackjobs4.me/user/sign_up", {
         method : "POST",
         body : form,
         credentials : "include"
      });
    };
  
  /* Get fetch() response and react accordingly */
  let handleCreateAccount = (e) => {
      submitFormData()
      .then(response => {
        setLoading(false);
        if (response.ok) { history.push("/my-jobs") } 
        else if (response.status === 409) { setshowUserAlreadyExistsError(true) } 
        else { setShowDefaultError(true) }
      }).catch(function (error) {
        setLoading(false);
        setShowDefaultError(true);
        console.log(error);
      })
    };

    return (
      <LoadingOverlay 
      active = {loading}
      spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
      styles={{
          overlay: (base) => ({
            ...base,
            backgroundColor: 'currentColor'
          })
        }}
      >
        <Container>
        <hr tw="mt-4"></hr>
        <Content>
            <TwoColumn>
                <LeftColumn>
                  <h2 tw="text-3xl md:text-4xl font-extrabold text-orange-500">Ready to get started?</h2>
                  <h5 tw="tracking-wide">It's free, and it'll only take a couple of seconds.</h5>
                </LeftColumn>

                <RightColumn>
                  <a href="/forgot-password"><ErrorLabel title={"User Already Exists"} message={"Looks like that email is already in use. Forgot your password? Click here."} visibility={showUserAlreadyExistsError} setVisibility={setshowUserAlreadyExistsError}/></a>
                  <ErrorLabel visibility={showDefaultError} setVisibility={setShowDefaultError}/>
                  <form>
                    <Input type="text" placeholder="First Name" maxLength="24" required onChange={(e) => { setName(e.target.value) }}/>
                    <Input type="email" placeholder="Email" required onChange={(e) => { setEmail(e.target.value) }}/>
                    <ErrorLabel title={"Invalid Email Address"} message={"Hmm, looks like you entered an invalid email. \n Please double check that and try again!"} visibility={showEmailError} setVisibility={setShowEmailError}/>
                    <Input type="password" placeholder="Password, must be at least 6 characters" required onChange={(e) => { setPassword(e.target.value) }}/>
                    <ErrorLabel title={"Invalid Password"} message={"Looks like you entered an invalid password. \n Remember, it needs to be at least 6 characters."} visibility={showPasswordError} setVisibility={setShowPasswordError}/>

                    <p tw="mt-6 text-xs text-gray-600 text-center">By creating an account, I agree to trackjobs4me's{" "}<a href="https://www.termsfeed.com/live/cba70978-ae1e-464c-bd3d-495f0b7eef8f" target="_blank" rel="noopener noreferrer" tw="border-b border-gray-500 border-dotted">privacy policy</a></p>
                    
                    <LazyLoad offset={0} debounce={false} onContentVisible={() => console.log('ReCAPTCHA has been lazy loaded.')}> 
                      <div tw="flex flex-row items-center justify-center mt-4">  
                        <ReCAPTCHA
                        sitekey="6LcpLc0ZAAAAAI0uPxuTe7IMBYPVG05KRalGk4uX"
                        onChange={token => setCaptchaToken(token)}
                        onExpired={e => setCaptchaToken("")}
                        />
                      </div>
                    </LazyLoad>
                    
                    <SubmitButton onClick={formHandler}>Create Account</SubmitButton>
                  </form>         
                  <UserCheck><a href="/sign-in" rel="noopener noreferrer">Already have an account?<br></br> Click here to sign in!</a></UserCheck>
                </RightColumn>
            </TwoColumn>
        </Content>
      </Container>
    </LoadingOverlay>
  );
};
