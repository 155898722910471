import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { useState } from "react";
/* Component Imports */
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
import ErrorLabel from "../app-components/ErrorLabel.js"
import { useHistory } from "react-router-dom";
/* Icon Imports */
import logo from "app-images/logo.svg";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import arrow from "app-images/arrow.svg"

/* All of the element stylings */
const Container = tw.div`relative min-h-screen bg-gray-500 text-white font-medium flex justify-center items-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`w-full md:w-8/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto mb-2`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-4`;
const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0 text-secondary-500`;
const SubmitButton = styled.button`${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
.icon { ${tw`w-6 h-6 -ml-2`} }
.text { ${tw`ml-3`} } `;
const ExitImage = tw.img`max-w-xs h-5 mt-4`;

/* Login Page Component Itself */
export default () => {
  const [showAuthError, setShowAuthError] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  let formHandler = (e) => {
    /* Set state again incase something's active */
    setShowAuthError(false);
    setShowDefaultError(false);
    e.preventDefault(); /* Prevent any default form submissions */
    if (email === "" || password === "") { return }
    /* In all other cases, pass off to make API request */
    handleUserLogin();
  };

  /* Get form data and make fetch() request */
  let submitFormData = () => {
      setLoading(true);
      let form = new FormData();
      form.append("email", email.toLowerCase());
      form.append("password", password);
      return fetch("https://api.trackjobs4.me/user/sign_in", {
        method : "POST",
        body : form,
        credentials : "include"
      });
    };
  
  /* Get fetch() response and react accordingly */
  let handleUserLogin = (e) => {
      submitFormData()
      .then(response => {
        setLoading(false);
        if (response.ok) { history.push("/my-jobs") } 
        else if (response.status === 401) { setShowAuthError(true) } 
        else { setShowDefaultError(true) }
      }).catch(function (error) {
        setLoading(false);
        setShowDefaultError(true);
        console.log(error);
      });
    };

  return (
      <LoadingOverlay 
      active = {loading}
      spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
      styles={{
          overlay: (base) => ({
            ...base,
            backgroundColor: 'currentColor'
          })
        }}
      >
        <AnimationRevealPage>
          <Container>
            <Content>
              <MainContainer>
                <LogoLink href="/">
                  <LogoImage src={logo} />
                  <Heading>TRACKJOBS4.ME</Heading>
                </LogoLink>
                <MainContent>
                  <FormContainer>
                  <a href="/forgot-password"><ErrorLabel title={"Invalid Login Credentials"} message={"Looks like you entered an invalid email or password. \nPlease try again, or click here to reset your password."} visibility={showAuthError} setVisibility={setShowAuthError}/></a>
                  <ErrorLabel visibility={showDefaultError} setVisibility={setShowDefaultError}/>                  
                  <Form>
                    <Input type="email" name="email" placeholder="Email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                    <Input type="password" name="password" placeholder="Password" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                    <SubmitButton type="submit" onClick={formHandler}>
                      <LoginIcon className="icon" />
                      <span className="text">Sign In</span>
                    </SubmitButton>
                  </Form>
                  <p tw="mt-6 text-xs text-gray-600 text-center"><a href="/forgot-password" tw="border-b border-gray-500 border-dotted">Forgot Your Password?</a></p>
                  <p tw="mt-4 text-sm text-gray-600 text-center">Don't have an account?{" "}<a href="/sign-up" tw="border-b border-gray-500 border-dotted">Sign Up</a></p>
                  </FormContainer>
                  <LogoLink href="/"><ExitImage src={arrow} alt=""/></LogoLink>
                </MainContent>
              </MainContainer>
            </Content>
          </Container>
        </AnimationRevealPage>
    </LoadingOverlay>
  ); 
};