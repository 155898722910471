import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { useState } from "react";
import { useHistory } from "react-router-dom";
/* Component Imports */
import SuccessLabel from '../app-components/SuccessLabel.js';
import ErrorLabel from '../app-components/ErrorLabel.js';
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
import ErrorPage from '../app-pages/ErrorPage.js'
/* Icon Imports */
import logo from "app-images/logo.svg";
import save from "app-images/save.svg"

/* All of the element stylings */
const Container = tw.div`relative min-h-screen bg-gray-500 text-white font-medium flex justify-center items-center -m-8`;
const Content = tw.div` m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center items-center flex-1`;
const MainContainer = tw.div`w-full md:w-8/12  p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto mb-2`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-2`;
const Form = tw.form`mx-auto max-w-md`;
const Input = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm text-secondary-500 focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon { ${tw`w-6 h-6 -ml-2`} }
  .text { ${tw`ml-3`} } `;
const ResetIcon = tw.img`max-w-xs h-6`

export default () => {
  /* Query params required for password reset is token */
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');  
  if (token === null || token === "") { return <ErrorPage />};

  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmedPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const [showMatchError, setShowMatchError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  let submitForm = (e) => {
    setShowDefaultError(false);
    setShowMatchError(false);
    setShowPasswordError(false);
    setShowSuccess(false);
    e.preventDefault();

    /* Validate Input */
    if (newPassword.length < 6 || confirmPassword.length < 6) { setShowPasswordError(true); return; };
    if (newPassword !== confirmPassword) { setShowMatchError(true); return; };

    setLoading(true);
    let form = new FormData();
    form.append('token', token);
    form.append('password', newPassword);
    form.append('confirm_password', confirmPassword);

    fetch("https://api.trackjobs4.me/user/reset_password", {
      method : "POST",
      body : form,
    })
    .then((response) => {
      setLoading(false);
      if (response.ok) {
        setShowSuccess(true);
        setTimeout(() => {
          history.push('/sign-in');
        }, 3000);
      } else {
        setShowDefaultError(true);
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log(error);
    })
  };

  return (
    <LoadingOverlay 
      active = {loading}
      spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
      styles={{
          overlay: (base) => ({
            ...base,
            backgroundColor: 'currentColor'
          })
        }}
      >
      <AnimationRevealPage>
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href="/">
                <LogoImage src={logo} />
                <Heading>TRACKJOBS4.ME</Heading>
              </LogoLink>
              <MainContent>
                <h1 tw="text-center text-base w-full">You have 5 minutes from the time you requested a password reset to change your password. <br></br>
                <span tw="font-bold"> If more than 5 minutes have elapsed, please request a new reset email.</span>
                </h1>
                <FormContainer>
                  <ErrorLabel visibility={showDefaultError} setVisibility={setShowDefaultError} message={"Looks like there was an error. Please make sure you are within the 5 minute window for password resets, and you haven't already reset your password. Otherwise please request another reset email."}></ErrorLabel>
                  <ErrorLabel visibility={showPasswordError} setVisibility={setShowPasswordError} title={"Password Length Error"} message={"Looks like you entered an invalid password. Remember, it needs to be at least 6 characters in length!"}></ErrorLabel>
                  <ErrorLabel visibility={showMatchError} setVisibility={setShowMatchError} title={"Password Match Error"} message={"Looks like your passwords don't match. Please make sure they're the same and try again."}></ErrorLabel>
                  <SuccessLabel visibility={showSuccess} setVisibility={setShowSuccess} title={"Success!"} message={"Your password has been successfully changed. Redirecting you to the login page now!"}></SuccessLabel>
                  <Form>
                    <Input type="password" placeholder="New Password, must be at least 6 characters" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) } }/>
                    <Input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => { setConfirmedPassword(e.target.value) } }/>
                    <SubmitButton type="submit" onClick={submitForm}>
                      <ResetIcon src={save} alt="" />
                      <span className="text">Save New Password</span>
                    </SubmitButton>
                  </Form>
                </FormContainer>
              </MainContent>
            </MainContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
    </LoadingOverlay>
  );
}
