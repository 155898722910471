import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useState } from "react";
import { useHistory } from "react-router-dom";
/* Component Import */
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
import ReCAPTCHA from "react-google-recaptcha";
import ErrorLabel from "../app-components/ErrorLabel.js";
/* Icon Import */
import logo from "app-images/logo.svg";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import arrow from "app-images/arrow.svg"

const Container = tw.div`relative min-h-screen bg-gray-500 text-white font-medium flex justify-center items-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`w-full md:w-8/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto mb-2`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-4`;
const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0 text-secondary-500`;
const SubmitButton = styled.button` ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
.icon { ${tw`w-6 h-6 -ml-2`} }
.text { ${tw`ml-3`} } `;
const ExitImage = tw.img`max-w-xs h-5 mt-4`;

export default () => {
  /* Various hooks */
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showUserAlreadyExistsError, setshowUserAlreadyExistsError] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  /* API params */
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  let formHandler = (e) => {
    /* Set state again incase something's active */
    setShowEmailError(false);
    setShowPasswordError(false);
    setshowUserAlreadyExistsError(false);
    setShowDefaultError(false);
    e.preventDefault(); /* Prevent any default form submissions */
    const regex = /^[A-Za-z0-9]+[._]?[a-z0-9]+[@]\w+[.]\w{2,3}$/g;
    let match = email.match(regex);
    if (name === "" || email === "" || password === "" || captchaToken === "") { return }
    if (!match) { setShowEmailError(true); return }
    if (password.length < 6) { setShowPasswordError(true); return }
    /* In all other cases, pass off to make API request */
    handleCreateAccount();
  };
  
  /* Get form data and make fetch() request */
  let submitFormData = () => {
      setLoading(true);
      let form = new FormData();
      form.append("name", name)
      form.append("email", email.toLowerCase());
      form.append("password", password);
      form.append("client_token", captchaToken);
      return fetch("https://api.trackjobs4.me/user/sign_up", {
        method : "POST",
        body : form,
        credentials : "include"
      });
    };
  
  /* Get fetch() response and react accordingly */
  let handleCreateAccount = (e) => {
      submitFormData()
      .then(response => {
        setLoading(false);
        if (response.ok) { history.push("/my-jobs") } 
        else if (response.status === 409) { setshowUserAlreadyExistsError(true) } 
        else { setShowDefaultError(true) }
      }).catch(function (error) {
        setLoading(false);
        setShowDefaultError(true);
        console.log(error)
      });
    };

  return (
    <LoadingOverlay 
    active = {loading}
    spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
    styles={{
        overlay: (base) => ({
          ...base,
          backgroundColor: 'currentColor'
        })
      }}
    >
      <AnimationRevealPage>
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href="/">
                <LogoImage src={logo} />
                <Heading>TRACKJOBS4.ME</Heading>
              </LogoLink>
              <MainContent>
                <FormContainer>
                    <a href="/forgot-password"><ErrorLabel title={"Email Address Error"} message={`Looks like that email is already in use. Click here if you forgot your password!`} visibility={showUserAlreadyExistsError} setVisibility={setshowUserAlreadyExistsError}/></a>
                    <ErrorLabel visibility={showDefaultError} setVisibility={setShowDefaultError}/>
                    <Form>
                      <Input type="text" placeholder="First Name" required maxLength="24" value={name} onChange={(e) => {setName(e.target.value)}}/>
                      <Input type="email" placeholder="Email" required  value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                      <ErrorLabel title={"Invalid Email Address"} message={"Hmm, looks like you entered an invalid email. \n Please double check that and try again!"} visibility={showEmailError} setVisibility={setShowEmailError}/>
                      <Input type="password" placeholder="Password, must be at least 6 characters" required  value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                      <ErrorLabel title={"Invalid Password"} message={"Looks like you entered an invalid password. \n Remember, it needs to be at least 6 characters."} visibility={showPasswordError} setVisibility={setShowPasswordError}/>
                      <p tw="mt-6 text-xs text-gray-600 text-center">By signing up, I agree to trackjobs4me's{" "}<a href="https://www.termsfeed.com/live/cba70978-ae1e-464c-bd3d-495f0b7eef8f" target="_blank" rel="noopener noreferrer" tw="border-b border-gray-500 border-dotted">privacy policy</a></p>

                      <div tw="flex flex-row items-center justify-center mt-4">  
                        <ReCAPTCHA
                        sitekey="6LcpLc0ZAAAAAI0uPxuTe7IMBYPVG05KRalGk4uX"
                        onChange={token => setCaptchaToken(token)}
                        onExpired={e => setCaptchaToken("")}
                        />
                      </div>

                      <SubmitButton type="submit" onClick={formHandler}>
                        <SignUpIcon className="icon" />
                        <span className="text">Sign Up</span>
                      </SubmitButton>
                      <p tw="mt-4 text-sm text-gray-600 text-center">Already have an account?{" "}<a href="/sign-in" tw="border-b border-gray-500 border-dotted">Sign In</a></p>
                    </Form>
                </FormContainer>
                <LogoLink href="/"><ExitImage src={arrow} alt=""/></LogoLink>
              </MainContent>
            </MainContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
    </LoadingOverlay>
  );
};
