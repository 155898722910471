import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useState } from "react";
/* Component Imports */
import Modal from 'react-modal';
import NotesModal from "./NotesModal.js";
import RowModal from "./RowModal.js";
import ErrorLabel from "../ErrorLabel.js"
/* Image imports */
import edit from "../../app-images/edit.svg";
import deleteSVG from "../../app-images/delete.svg";
import url from "../../app-images/url.svg";
import company from "../../app-images/company_2.svg";
import notes from "../../app-images/notes.svg";

/* Style Stuff */
const Container = tw.div`w-full flex flex-col md:flex-row items-center justify-between rounded-sm shadow-lg border p-2 my-2 bg-gray-100`;
const LeftColumn = tw.div`flex flex-col items-center justify-center w-full md:w-11/12`;
const LeftColumnRow = tw.div`flex flex-row items-center justify-center w-full border-b md:border-none`
const LeftColumnItem = tw.div`w-full flex flex-row items-center justify-center border-r`;
const LeftColumnItemText = tw.h1`font-normal tracking-wide text-center text-xs md:text-sm m-1`;
const RightColumn = tw.div`flex flex-row md:flex-col items-center justify-around w-full md:w-1/12 my-2 md:my-0`;
const Label = tw.p`text-center font-bold text-white rounded w-auto p-2 text-xs m-2 w-full md:w-2/3 `
const Icon = tw.img`max-w-xs h-5 md:h-8 m-2`
const Button = tw.button`border-none focus:outline-none`
const OverrideModalStyles = { content : { top : '50%', left : '50%', right : 'auto', bottom : 'auto', marginRight : '-50%', transform : 'translate(-50%, -50%)', padding : '0px', minWidth : '50%', maxWidth : '75%', borderRadius : '0.25rem', overflow : 'scroll', maxHeight : "100vh" } };

/* Pass down the properties to this features component */
export default ({ 
    RowID = null, 
    DateAndTime = null, 
    CompanyName = null, 
    JobTitle = null, 
    JobURL = null, 
    Notes = null, 
    Status = null, 
    deleteOnClient = null, 
    overwriteRowOnClient = null
}) => {
    /* Hooks for local state management ... only for the things that might need to be updated 
    initial values are set from the props that are passed down, and any server updates will occur 
    locally until the next page refresh */
    const [localCompanyName, setLocalCompanyName] = useState(CompanyName);
    const [localJobTitle, setLocalJobTitle] = useState(JobTitle);
    const [localURL, setLocalURL] = useState(JobURL);
    const [localNotes, setLocalNotes] = useState(Notes);
    const [localStatus, setLocalStatus] = useState(Status);

    /* Hook for misc. items */
    const [companyLogo, setCompanyLogo] = useState('');
    const [notesModalState, setNotesModalState] = useState(false);
    const [rowModalState, setRowModalState] = useState(false);
    const [visibile, setVisible] = useState(true);

    /* Error handling */
    const [deleteError, showDeleteError] = useState(false);
    const [updateError, showUpdateError] = useState(false);

    /* Modal event listeners */
    let openNotesModal = () => { setNotesModalState(true) };
    let closeNotesModal = () => { setNotesModalState(false) };
    let openRowModal = () => { setRowModalState(true) };
    let closeRowModal = () => { setRowModalState(false) };

    /* Set local state on success from the server */
    let updateLocalState = (data) => {
        setLocalCompanyName(data.company);
        setLocalJobTitle(data.title);
        setLocalURL(data.url);
        setLocalNotes(data.notes);
        setLocalStatus(data.status);
    };

    /* Update row function, if a parameter isn't passed the component's current state values 
    are used, but if a value is passed, it'll overrwrite any existing data when POST'ed to 
    the API and database */
    let updateRow = (options) => {

        let reconcileNoteState = () => {
            if (options.newNotes !== undefined) {
                if (options.newNotes === "") {
                    return "";
                }
                return options.newNotes;
            }
            return localNotes;
        };

        let new_data = {
            'date' : DateAndTime, 'company' : ( options.newCompany || localCompanyName ),
            'title' : ( options.newTitle || localJobTitle ), 'url' : ( options.newLink || localURL ),
            'notes' : reconcileNoteState() , 'status' : ( options.newStatus ? options.newStatus : localStatus ),
            'row_id' : RowID
        };

        let new_data_JSON = JSON.stringify(new_data);
        let form = new FormData();
        form.append("row_id", RowID);
        form.append("new_data", new_data_JSON);

        fetch("https://api.trackjobs4.me/user/data/update_entry", { method : "POST", body : form, credentials : "include" })
        .then(response => { 
            if (response.ok) { updateLocalState(new_data); overwriteRowOnClient(new_data); } 
            else { showUpdateError(true) }
        })
        .catch( (error) => { showUpdateError(true); console.log(error) });
    };

    /* Delete row function, calls the API to delete a row based on its unique ID */
    let deleteRow = (RowID) => {
        let form = new FormData();
        form.append("row_id", RowID);
        fetch('https://api.trackjobs4.me/user/data/delete_entry', { method : "DELETE", credentials : "include", body: form })
        .then(response => { 
            if (response.ok) { setVisible(false); deleteOnClient(RowID); } 
            else { showDeleteError(true); }
        })
        .catch((error) => { showDeleteError(true); console.log(error) })
    };

    /* Function to format the date & time without seconds */
    let formatDate = (DateAndTime) => {
        try {
            /* Some clients will use millitary time in their browsers. To ensure these times are properly formatted, 
            check to see if the DateAndTime string contains either 'AM' || 'PM' */
            if (DateAndTime.includes('AM') || DateAndTime.includes('PM')) {
                return DateAndTime.substring(0, DateAndTime.length-6) + ' ' + DateAndTime.substring(DateAndTime.length-2);
            }
            /* All non-standard time formats go here .. just return them as is */
            return DateAndTime;
        } catch (error) { console.log(error); return DateAndTime; }
    };

    /* Utilize Clearbit's API to get company logo if it exists ... only checking '.com' endings */
    let getCompanyLogo = (CompanyName, JobURL) => {

        /* Helper function to determine company TLD from job posting url 
        This method isn't still 100% accurate since a company can have a different
        TLD for their careers page but this is as close as it'll get for now I think */
        let getTopLevelDomain = (link) => {
            try {
                let temp = new URL(String(link)); /* https://trackjobs4.me/ */
                let host = temp.hostname; /* trackjobs4.me */
                let arr = host.split(".") /* [ "trackjobs4" , "me" ] */
                let tld = arr[arr.length-1]; /* me */
                return String(tld);
            } catch (error) {
                return "com";
            }
        };

        let tld = getTopLevelDomain(JobURL);
        let temp = CompanyName.replace(/[^\w/s+]/g, '');
        let endpoint = `https://logo.clearbit.com/${temp}.${tld}?&size=512`;

        /* First try to get an image based off the job postings TLD. if that doesn't work,
        default to using the '.com' TLD ... & if that doesn't work, use the default logo.
        This addition is after noticing companies that use ATS systems on different TLD's than
        their own site. For example, PagerDuty.com used jobs.lever.co for its applications */
        fetch(endpoint, {method : "GET"})
        .then((response) => { 
            if (response.ok) { 
                setCompanyLogo(endpoint) 
            }
        })
        .catch((error) => {
            let defaultEndpoint = `https://logo.clearbit.com/${temp}.com?&size=512`;
            fetch(defaultEndpoint, {method : "GET"})
            .then((response) => {
                if (response.ok) {
                    setCompanyLogo(defaultEndpoint);
                }
            })
            .catch((error) => {
                setCompanyLogo(company);
            })
        })
    };

    /* Before assigning any labels, auto update any rows older than 30 days with a stale status on the server */
    let StatusLabel = () => {
        /* Helper method */
        let calculateAge = (DateAndTime) => {
            try {
                let oldDate = new Date(DateAndTime);
                let currDate = new Date();
                let diff = currDate.getTime() - oldDate.getTime();
                let days = diff / (1000*3600*24)
                return days.toFixed()
            } catch (error) {
                console.log(error);
                return 0;
            }
        };
        
        let labelMap = {
            "active" : <Label tw="bg-green-500">ACTIVE</Label>,
            "inactive" : <Label tw="bg-red-500">INACTIVE</Label>,
            "stale" : <Label tw="bg-gray-500">STALE</Label>,
            "ongoing" : <Label tw="bg-black">ONGOING</Label>,
            "phone-screen" : <Label tw="bg-purple-500">PHONE SCREEN</Label>,
            "interview" : <Label tw="bg-blue-500">INTERVIEW</Label>,
            "onsite" : <Label tw="bg-yellow-500">ONSITE</Label>,
            "offer" : <Label tw="bg-orange-500">OFFER</Label>,
        };

        /* Before assigning any labels, auto update any rows older than 30 days with a stale status on the server */
        if (calculateAge() > 30) {
            updateRow( {newStatus : "stale"} )
            return labelMap['stale'];
        };

        let temp = ( labelMap[localStatus] ? labelMap[localStatus] : labelMap['active'])
        return temp;
    }

    getCompanyLogo(localCompanyName, localURL);

    /* Fixes issue where users forget to prepend https:// or http:// */
    let openLink = () => {
        if (!JobURL.startsWith("http")) {
            /* default to http, ssl redirects will be handled by dns */
            JobURL = 'http://'+JobURL;
        } 
        window.open(JobURL, "_blank");
    };

/* ----------------------------------------------------------------------------------------------*/
    if (visibile) {
        return (
            <>
                <ErrorLabel title={"Delete Error"} message={`Looks like there was an error deleting ${localJobTitle} at ${localCompanyName}. Please try again in a few seconds!`} visibility={deleteError} setVisibility={showDeleteError}/>
                <ErrorLabel title={"Update Error"} message={`Looks like there was an error updating ${localJobTitle} at ${localCompanyName}. Please try again in a few seconds!`} visibility={updateError} setVisibility={showUpdateError}/>
                <Container>
                    <LeftColumn>
                        <LeftColumnItem tw="border-b md:justify-start border-r-0 md:border-r">
                            <LeftColumnItemText tw="text-base md:text-lg font-semibold my-2 md:ml-3 md:text-left">{localJobTitle}</LeftColumnItemText>
                        </LeftColumnItem>

                        <LeftColumnRow tw="items-stretch">
                            <LeftColumnItem>
                                <Icon src={companyLogo} alt="" tw="hidden md:block md:h-6"/>
                                <LeftColumnItemText tw="font-medium">{localCompanyName}</LeftColumnItemText>
                            </LeftColumnItem>

                            <LeftColumnItem>
                                <LeftColumnItemText>{formatDate(DateAndTime)}</LeftColumnItemText>
                            </LeftColumnItem>

                            <LeftColumnItem tw="md:w-1/3">
                                <Icon role="button" src={url} alt="" onClick={openLink}/>
                                <Modal isOpen = {notesModalState} style = {OverrideModalStyles} >
                                    <NotesModal CompanyName={localCompanyName} JobTitle={localJobTitle} Notes={localNotes} CloseModal={closeNotesModal} updateRow={updateRow}/>
                                </Modal>
                            </LeftColumnItem>

                            <LeftColumnItem tw="md:w-1/3">
                                <Button onClick={openNotesModal}><Icon src={notes} alt="" /></Button>
                            </LeftColumnItem>

                            <LeftColumnItem tw="border-r-0 md:border-r md:w-1/2">{StatusLabel()}</LeftColumnItem>

                        </LeftColumnRow>
                    </LeftColumn>

                    <RightColumn>
                        <Button onClick={openRowModal}>
                            <Icon src={edit} alt="" tw="md:h-5 md:my-4"/>
                        </Button>
                        
                        <Modal isOpen = {rowModalState} style = {OverrideModalStyles} >
                            <RowModal 
                                DateAndTime={DateAndTime} 
                                CompanyName={localCompanyName} 
                                JobTitle={localJobTitle} 
                                URL={localURL} 
                                Notes={localNotes} 
                                Status={localStatus}
                                CloseModal={closeRowModal} 
                                updateRow={updateRow}/>
                        </Modal>

                        <Button onClick={() => deleteRow(RowID) }>
                            <Icon src={deleteSVG} alt="" tw="md:h-5 md:my-4"/>
                        </Button>
                    </RightColumn>
                </Container>
            </>
        )
    } else {
        return null;
    }
}; 