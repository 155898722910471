import React from 'react';
import tw from "twin.macro";
import { useState, useEffect } from "react";

const Container = tw.div`relative`
const ButtonContainer = tw.div`flex flex-row items-center justify-center flex-wrap`;
const Button = tw.button`bg-gray-300 hover:bg-blue-600 text-secondary-500 font-bold py-2 px-3 m-1 rounded shadow-md`;
const Label = tw.h1`tracking-wide text-secondary-500 font-medium text-center`;
const LabelContainer = tw.div`flex flex-col items-center justify-center my-1`

export default ({ 
    jobsPerPage = null, 
    totalJobs = null, 
    paginate = null, 
    indexOfFirstJob = null, 
    indexOfLastJob = null 
}) => {
    /* NOTE ABOUT A PRIOR ISSUE => Lets say you start with 5 results per page, and have 15 results.
    Then you go to the third page, and decide you wanna see 10 results per page
    The parent component has a useEffect() hook that'll detect changes in the jobsPerPage value, as well as search bar value
    Anytime these two are updated, the current page value is set to 1..
    Then whenever a pagination button is clicked, this component's state will match the parent component's state */

    let pageNumbers = [];
    const [currentPage, setCurrentPage] = useState(1);
    for (let i = 1; i <= Math.ceil(totalJobs / jobsPerPage); i++) { pageNumbers.push(i) }

    useEffect(() => {
        setCurrentPage(1);
    }, [jobsPerPage, totalJobs])

    if (totalJobs < jobsPerPage) { /* No need for pagination in this case */
        return null; 
    } else {
        return (
            <Container>
                <LabelContainer>
                    <Label>You're on page <span tw="font-black">{currentPage}</span> viewing {indexOfFirstJob+1} to {indexOfLastJob+1 > totalJobs ? totalJobs : indexOfLastJob} of {totalJobs} total jobs</Label>
                </LabelContainer>
                <ButtonContainer>
                    {pageNumbers.map(number => (
                        <Button style={ { backgroundColor : number === currentPage ? "#63b3ed" : ""}} key={number} onClick={() => {
                            paginate(number);
                            setCurrentPage(number)
                        }} >{number}</Button>
                    ))}
                </ButtonContainer>
            </Container>
        )
    }
};