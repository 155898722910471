import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Nav from "app-components/Nav.js";
import User from "app-components/Account/User.js";
import Footer from "app-components/Footer.js";

export default () => {
  return (
    <AnimationRevealPage>
    <Nav/>
    <User />
    <Footer />
    </AnimationRevealPage>
  )
}



