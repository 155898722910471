import React from "react";
import { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
/* Component Imports */
import SuccessLabel from '../app-components/SuccessLabel.js';
import ErrorLabel from '../app-components/ErrorLabel.js';
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
/* Image imports */
import logo from "app-images/logo.svg";
import save from "app-images/save.svg"
import arrow from "app-images/arrow.svg"

/* All of the element stylings */
const Container = tw.div`relative min-h-screen bg-gray-500 text-white font-medium flex justify-center items-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center items-center flex-1`;
const MainContainer = tw.div`w-full md:w-8/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto mb-2`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1 mt-2`;
const Form = tw.form`mx-auto `;
const Input = tw.input`w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm text-secondary-500 focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon { ${tw`w-6 h-6 -ml-2`} }
  .text { ${tw`ml-3`} } `;
const SubHeading = tw.p`w-full text-sm font-semibold text-center mt-2 mb-1 text-secondary-500 leading-relaxed`
const ResetIcon = tw.img`max-w-xs h-6`
const TextArea = tw.textarea`h-24 w-full px-4 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm text-secondary-500 focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const ExitImage = tw.img`max-w-xs h-5 mt-4`;

export default () => {
  /* Form Parameters */
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [url, setURL] = useState(urlParams.get('url') || '');
  const [companyName, setCompanyName] = useState(urlParams.get('company') || '');
  const [jobTitle, setJobTitle] = useState(urlParams.get('title') || '');
  const [extraWords, setExtraWords] = useState('');
  const [comments, setComments] = useState('');

  /* Error States */
  const [showAuthError, setShowAuthError] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  /* Function to handle form */
  let submitForm = (e) => {
    e.preventDefault();
    if (url === "" || companyName === "" || jobTitle === "") { return; }
    setLoading(true);
    setShowAuthError(false);
    setShowDefaultError(false);
    setShowSuccess(false);
  
    let form = new FormData();
    form.append('url', url);
    form.append('company', companyName);
    form.append('title', jobTitle);
    form.append('extra_words', extraWords)
    form.append('comments', comments);

    fetch("https://api.trackjobs4.me/user/data/submit_feedback", {
      method : "POST",
      body : form,
      credentials : "include"
    })
    .then((response) => {
      setLoading(false);
      if (response.ok) { setShowSuccess(true) } 
      else if (response.status === 401) { setShowAuthError(true) }
    })
    .catch((error) => {
      setLoading(false);
      setShowDefaultError(true);
      console.log(error);
    })
  };

  return (
    <LoadingOverlay 
    active = {loading}
    spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
    styles={{
        overlay: (base) => ({
          ...base,
          backgroundColor: 'currentColor'
        })
      }}
    >
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href="/">
              <LogoImage src={logo} />
              <Heading>SUBMIT FEEDBACK</Heading>
            </LogoLink>
            <MainContent>
                <SubHeading>The trackjobs4.me tool makes a calculated guess on what a job's details are based on the words on a webpage. Unfortunately, the tool isn't entirely accurate sometimes. We'd love to hear from you, the user, on what sites the tool isn't working on so we can help the tool be better.</SubHeading>
                <SubHeading>Just fill out the form below and we'll be able to use the info you provide for improvements!</SubHeading>
              <FormContainer>
                <Form>
                  {/* Error Labels */}
                  <ErrorLabel visibility={showDefaultError} setVisibility={setShowDefaultError}/>
                  <ErrorLabel visibility={showAuthError} setVisibility={setShowAuthError} title={"Error"} message={"You need to be logged in to submit a feedback entry. \n Please log in and then try again."} />
                  <SuccessLabel visibility={showSuccess} setVisibility={setShowSuccess} title={"Thank you!"} message={"Your feedback was succesfully submitted.\n Thanks for helping improve the trackjobs4.me app!"} />
                  {/* Inputs */}
                  <Input type="text" placeholder="Job Posting URL" value={url} onChange={(e) => { setURL(e.target.value) } }/>
                  <Input type="text" placeholder="Company Name" value={companyName} onChange={(e) => { setCompanyName(e.target.value) } }/>
                  <Input type="text" placeholder="Job Title" value={jobTitle} onChange={(e) => { setJobTitle(e.target.value) } }/>
                  <TextArea type="text" placeholder="Extra Words (i.e. words that shouldn't have appeared in either company name or job title, separated by commas)" value={extraWords} onChange={(e) => { setExtraWords(e.target.value) } }/>
                  <TextArea type="text" placeholder="Comments & Suggestions" value={comments} onChange={(e) => { setComments(e.target.value) } }/>
                  <SubmitButton type="submit" onClick={submitForm}>
                    <ResetIcon src={save} alt="" />
                    <span className="text">Submit Feedback</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
              <LogoLink href="/"><ExitImage src={arrow} alt=""/></LogoLink>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
    </LoadingOverlay>
  );
}
