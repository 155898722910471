import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useState, useEffect } from "react";
/* Component Imports */
import ErrorLabel from '../ErrorLabel.js';
import SuccessLabel from '../SuccessLabel.js';
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';

/* Setup the styling using tailwind macro */
const Container = tw.div`relative`;
const Content = tw.div`my-4 md:my-8 flex flex-col md:flex-row items-center justify-center w-full`;
const Heading = tw.h1`w-full text-center text-2xl lg:text-left lg:text-3xl font-light my-3`;
const Column = tw.div`flex flex-col my-2 md:my-0 md:mx-2`
const ColumnHeading = tw.h1`w-full text-left text-2xl font-light `;
const Label = tw.label`uppercase tracking-wide font-bold mb-1`;
const Input = tw.input`w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-2`
const SaveButton = tw.button`bg-orange-500 hover:bg-orange-700 text-white font-semibold py-2 px-4 border border-orange-500 rounded-lg mx-1 shadow`;

/* Pass down the properties to this features component */
export default () => {
    /* Utilize the useState() hook to assign a value to something for our component */
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [resetAccount, setResetAccount] = useState("")

    /* Hooks for error handling */
    const [showInvalidPasswordError, setShowInvalidPasswordError] = useState(false);
    const [showInvalidEmailError, setShowInvalidEmailError] = useState(false);
    const [showEmailInUseError, setShowEmailInUseError] = useState(false);
    const [showDefaultError, setShowDefaultError] = useState(false);
    const [showAuthError, setShowAuthError] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [loading, setLoading] = useState(true);
  
    /* Then utilize the useEffect() hook to call fetch(), only when the component is 
    mounted. Signaled by the empty array parameter at the end of the hook.. 
    This is used to set the user data values */
      useEffect(() => {
        fetch('https://api.trackjobs4.me/user/detect_session', {
            method : "GET",
            credentials : "include"
        })
        .then(response => response.json())
        .then((result) => {
            setName(result['NAME']);
            setEmail(result['EMAIL']);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    /* Validate form input to make sure there aren't any client errors */
    let validateInput = () => {
        setShowInvalidPasswordError(false);
        setShowInvalidEmailError(false);
        setShowEmailInUseError(false);
        setShowDefaultError(false);
        setShowAuthError(false);
        setShowSuccessAlert(false);
        const regex = /^[A-Za-z0-9]+[._]?[a-z0-9]+[@]\w+[.]\w{2,3}$/g;
        const match = email.match(regex);
        if (name === "" || email === "" || currentPassword === "") { return }
        if (!match) { setShowInvalidEmailError(true); return; }
        if (newPassword !== "" && confirmNewPassword !== "") {
            if ((newPassword !== confirmNewPassword) || (newPassword.length < 6 || confirmNewPassword.length < 6)) {
                setShowInvalidPasswordError(true);
                return; 
            }
        }
        /* In all other cases, go ahead and submit form */
        submitForm();
    };
    
    let submitForm = () => {
        setLoading(true);
        let form = new FormData();
        form.append('name', name);
        form.append('email', email);
        form.append('new_password', newPassword);
        form.append('confirm_new_password', confirmNewPassword);
        form.append('current_password', currentPassword);
        form.append('reset_account', resetAccount); 

        fetch("https://api.trackjobs4.me/user/update_account", {
            method : "POST",
            body : form,
            credentials : "include"
         })
        /* Render proper front-end object from API response */
         .then(response => {
             setLoading(false);
             if (response.ok) { setShowSuccessAlert(true); };
             if (response.status === 401) { setShowAuthError(true) }
             if (response.status === 403) { setShowInvalidPasswordError(true) };
             if (response.status === 409) { setShowEmailInUseError(true) };
         })
         .catch((error) => { console.log(error); })
    };

  return (
    <LoadingOverlay 
    active = {loading}
    spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
    styles={{
        overlay: (base) => ({
          ...base,
          backgroundColor: 'currentColor'
        })
      }}
    >
    <Container id="Overview">
        <hr tw="mt-4"></hr>
        <Heading>Manage Your Account</Heading>

        <Content>
            {/* BASIC INFO COLUMN */}
            <Column tw="w-full md:w-1/2"> 
                <ColumnHeading tw="font-bold ">Basic Info</ColumnHeading>
                <ColumnHeading tw="text-base mb-2 ">Edit any fields then enter your password and hit 'Save Changes'</ColumnHeading>
                <Label>First Name</Label>
                <Input value={name} onChange={(e) => {setName(e.target.value)}}></Input>
                <ErrorLabel 
                    title={"Invalid Email"}
                    message={"Looks like you entered an invalid email address. Please double check that and try again."}
                    visibility={showInvalidEmailError}
                    setVisibility={setShowInvalidEmailError}
                />
                <ErrorLabel 
                    title={"Email Error"}
                    message={"Looks like you entered an email address that's already in use. Please try another email address."}
                    visibility={showEmailInUseError}
                    setVisibility={setShowEmailInUseError}
                />
                <Label>Email</Label>
                <Input value={email} onChange={(e) => {setEmail(e.target.value)}}></Input>
            </Column>

            {/* PASSWORD CHANGE COLUMN */}
            <Column tw="w-full md:w-1/2">
                <ColumnHeading tw="font-bold ">Password Change</ColumnHeading>
                <ColumnHeading tw="text-base italic mb-2">Leave this blank to leave your password unchanged</ColumnHeading>
                <Label>New Password</Label>
                <Input 
                    type="password" 
                    placeholder="New Password (must be at least 6 characters)" 
                    value={newPassword}
                    onChange={(e) => {setNewPassword(e.target.value)}}
                />
                <Label>Confirm New Password</Label>
                <Input 
                    type="password" 
                    placeholder="Confirm New Password" 
                    value={confirmNewPassword} 
                    onChange={(e) => {setConfirmNewPassword(e.target.value)}}
                />
                <ErrorLabel 
                    title={"Password Error"} 
                    message={"Looks like you entered an invalid password. Make sure they both match, and are at least 6 characters in length."} 
                    visibility={showInvalidPasswordError}
                    setVisibility={setShowInvalidPasswordError}
                />
            </Column>
        </Content>


        <Content>
            {/* RESET YOUR ACCOUNT COLUMN */}
            <Column tw="w-full"> 
                <ColumnHeading tw="font-bold ">Reset Your Account</ColumnHeading>
                <ColumnHeading tw="text-base mb-2">Want to start fresh? Just enter your email address exactly how it appears above and all your jobs will be deleted</ColumnHeading>
                <Input placeholder="Leave this blank to leave your jobs unchanged" value={resetAccount} onChange={(e) => {setResetAccount(e.target.value)}}></Input>
            </Column>
        </Content>

        <hr tw="mt-4"></hr>

        <Content tw="flex md:flex-col items-center justify-center">
            <ErrorLabel 
                title={"Invalid Password"} 
                message={"Looks like you entered an invalid password. Your changes won't be saved until you enter the correct password. Please fix that and try again!"} 
                visibility={showAuthError}
                setVisibility={setShowAuthError}
            />
            <ErrorLabel 
                visibility={showDefaultError}
                setVisibility={setShowDefaultError}
            />
            <SuccessLabel
                title={"Confirmation"}
                message={"Your account updates have been successfully applied!"}
                visibility={showSuccessAlert}
                setVisibility={setShowSuccessAlert}
            />
            <Input tw="md:w-1/3" type="password" placeholder="Enter your current password to save changes" value={currentPassword} onChange={(e) => {setCurrentPassword(e.target.value)}}></Input>
            <SaveButton tw="my-2" onClick={validateInput}>Save Changes</SaveButton>
        </Content>

    </Container>
    </LoadingOverlay>
  );
};
