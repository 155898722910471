import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useState, useEffect } from "react";
/* Component Imports */
import JobRow from "./JobRow.js";
import Modal from 'react-modal';
import NewEntryModal from "./NewEntryModal.js";
import FadeIn from "react-fade-in";
import JobPagination from "./JobPagination.js"
/* Image Imports */
import insight from "../../app-images/insight.svg"
import search from "../../app-images/search.svg";
import download from "../../app-images/download.svg";
import add from "../../app-images/add.svg";

/* Setup the styling using tailwind macro */
const Container = tw.div`relative`;
const Content = tw.div`mx-auto my-4`;
const Heading = tw.h1`w-full text-center text-2xl lg:text-left lg:text-3xl font-light my-3`;
const ControlsContainer = tw.div`flex flex-col md:flex-row items-center justify-between`;
const Controls = tw.div`flex flex-col items-center md:flex-row my-2 lg:my-0`;
const Selector = tw.select`bg-gray-300 border rounded-lg px-4 py-2 mx-1 my-2 lg:my-0 appearance-none`;
const SearchContainer = tw.div`bg-gray-300 border rounded-lg relative mx-auto my-2 lg:my-0`
const SearchIcon = tw.img`max-w-xs h-4 absolute left-0 top-0 mx-3 my-3`
const SearchBar = tw.input`bg-gray-300 px-8 py-2 mx-1 outline-none`;
const Button = tw.button`px-6 py-2 bg-blue-400 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline transition duration-300 border rounded-lg mx-1`;
const ExportIcon = tw.img`max-w-xs h-6`
const AddIcon = tw.img`max-w-xs h-6`
const JobsTable = tw.div`flex flex-col items-center justify-center w-full my-4 text-secondary-500 `
const OverrideModalStyles = { content : { top : '50%', left : '50%', right : 'auto', bottom : 'auto', marginRight : '-50%', transform : 'translate(-50%, -50%)', padding : '0px', minWidth : '50%', borderRadius : '0.25rem' } };

/* Pass down the properties to this features component */
export default ({
    setJobsIntermediaryValue = null,
    setPageLoading = null,
}) => {
    const [showAddEntry, setShowAddEntry] = useState(false); /* Used to show the '+' button for adding a job on the website */
    const [jobs, setJobs] = useState([]) /* Used to set the initial state when fetching from the API */
    const [filterValue, setFilterValue] = useState(''); /* Set's the status filter */
    const [filteredResults, setFilteredResults] = useState([]); /* Returns array of jobs matching status filter */
    const [searchValue, setSearchValue] = useState(''); /* Set's the search value */
    const [searchResults, setSearchResults] = useState([]); /* Returns array of jobs matching status filter AND search results */
    const [currentPage, setCurrentPage] = useState(1); /* Set's the current page for pagination */
    const [jobsPerPage, setJobsPerPage] = useState(5); /* Set's the number of jobs to display per page */

    /* Various items for pagination within the job applications component */
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const currentJobs = searchResults.slice(indexOfFirstJob, indexOfLastJob); 
    const paginate = (pageNumber) => { setCurrentPage(pageNumber) }

    /* Basic functions for showing and closing modal containers */
    let openAddEntry = () => { setShowAddEntry(true) };
    let closeAddEntry = () => { setShowAddEntry(false) };

    /* Simple function to create a CSV string and then allow the user to download a file */
    let jobsToCSV = (JobJSON) => {
        let headers = ['Date & Time', 'Company', 'Job Title', 'URL', 'Notes', 'Status'];
        let csvString = headers.join(',') + "\n";
        JobJSON.forEach(job => {
            csvString += 
                JSON.stringify(job.date) + ',' + 
                JSON.stringify(job.company) + ',' + 
                JSON.stringify(job.title) + ',' + 
                JSON.stringify(job.url) + ',' + 
                JSON.stringify(job.notes) + ',' + 
                JSON.stringify(job.status) + "\n";
        });
        let newFile = document.createElement('a');
        newFile.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvString);
        newFile.download = `MyJobs.csv`;
        newFile.click();
        return;
    };

    /* Fetch from the DB again anytime an entry is added on the client so we have the ID that was created on the server */
    let fetchAgain = () => {
        fetch('https://api.trackjobs4.me/user/data/get_data', {
            method : "GET",
            credentials : "include"
        })
        .then(response => response.json())
        .then((result) => {
            /* React doesn't recongize a state change unless I set it null again ... because an array is a ref not an exact value */
            setJobs([]); 
            setJobs(result['USER DATA']);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    /* This executes after a client deletes a row. We want to update the jobs' state to reflect that because just hiding the row isn't enough. 
    If someone searches after deleting without refreshing the old value will still render itself on state change */
    let deleteOnClient = (row_id) => {
        let results = [];
        jobs.forEach(job => { if (job.row_id !== row_id) { results.push(job) } });
        setJobs(results); /* THIS WON'T FORCE A RE-RENDER SINCE IT'S JUST A REFERENCE CHANGE .. WHICH IS THE BEHAVIOR WE WANT ! */
        /* If you were to add setJobs([]); => then it'll force a re-render */
    };

    /* If someone edits a row the row is updated locally (within the row component), but the jobs array never knows about the update. 
    So this function gets passed down to the row component and gets called anytime a row's data is updated so it stays in sync with the jobs array */
    let overwriteRowOnClient = (new_data) => {
        let results = [];
        jobs.forEach(job => { 
            if (job.row_id === new_data.row_id) { results.push(new_data) }
            else { results.push(job) }
         })
         setJobs(results);
    };

    /* Then utilize the useEffect() hook to call fetch(), only when the component is 
    mounted. Signaled by the empty array parameter at the end of the hook */
    useEffect(() => {
        fetch('https://api.trackjobs4.me/user/data/get_data', {
            method : "GET",
            credentials : "include"
        })
        .then(response => response.json())
        .then((result) => {
            setJobs(result['USER DATA']);
            /* Since this is the initial componentDidMount() method, we change the page's state from loading here ! */
            setPageLoading(false); 
        })
        .catch((error) => {
            setJobs([])
            console.log(error);
        });
    }, [setJobs, setPageLoading]);

    /* Anytime one of the status filter selectors changes, return a list of the jobs that match this status 
    and set it to the filteredResults array. The useEffect() hook below this one will detect the change in 
    filteredResults and re-render based on whatever the search value is */
    useEffect(() => {
        try {
            const results = jobs.filter(job => {
                if (filterValue === "") return jobs;
                return job.status.toString().toLowerCase() === filterValue.toLowerCase()
            });
            setFilteredResults(results);
        } catch (error) {
            console.log(error)
        }
    }, [filterValue, jobs])

    /* Here's the hook for the search bar.. basically we render the table based on the filtered values array.
    This component is re-rendered anytime search value OR jobs changes because we're using the useEffect() hook
    The table is rendered based off the values in filteredJobs, which changes anytime the searchValue changes */
    useEffect(() => {
        const results = filteredResults.filter(job => {
            /* Trim input value's leading and trailing whitespace */
            let searchText = searchValue.trim();
            return job.company.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                job.title.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                job.url.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                job.notes.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                job.date.toString().toLowerCase().includes(searchText.toLowerCase())
        });
        setSearchResults(results);
    }, [searchValue, filteredResults]);

    /* Need this to reset the page number anytime the count per page is updated */
    /* Search results.length cause we don't want it to update when someone updates a row, but we do when someone adds a job */
    useEffect(() => {
        setCurrentPage(1);
    }, [jobsPerPage, filterValue, searchValue, searchResults.length]) 

    /* Anytime jobs changes, pass up the new jobs state to parent component so sibling can access it */
    useEffect(() => {
        setJobsIntermediaryValue(jobs);
    }, [setJobsIntermediaryValue, jobs])

    /* Sub-Components needed locally */
    let ClearBit = <>
        <FadeIn>
            <div tw="mx-auto md:mx-0 flex flex-row items-center justify-around border p-1 md:p-2 my-3 w-56 rounded-lg bg-blue-400 hocus:bg-blue-600 transition duration-300 ">
                <img src={insight} tw="max-w-xs h-4" alt=""></img>
                <a tw="text-xs md:text-sm text-center text-white font-sans" href="https://clearbit.com" target="_blank" rel="noopener noreferrer">Logos provided by Clearbit</a>
            </div>
        </FadeIn>
    </>;

    let NoJobsBanner = <>
        <FadeIn>
            <div tw="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 p-10 md:my-20 shadow-md w-full" >
                <div tw="flex">
                    <div tw="py-1"><svg tw="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                        <p tw="font-bold text-xl">No Job Applications</p>
                        <p tw="text-lg">Looks like you don't have any job applications yet. Start adding postings to see them appear here!</p>
                    </div>
                </div>
            </div>
        </FadeIn>
    </>;

    return (
        <Container>
            <hr tw="mt-4"></hr>
            <Heading>Your Job Applications</Heading>
            <Content>
                <ControlsContainer>
                    <Controls>
                        <Selector value={jobsPerPage} onChange={(e) => { setJobsPerPage(e.target.value)}} >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Selector>

                        <Selector value={filterValue} onChange={(e) => {setFilterValue(e.target.value)}}>
                            <option value="">All</option>
                            <option value="active" >Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="phone-screen">Phone Screen</option>
                            <option value="interview">Interview</option>
                            <option value="onsite">Onsite</option>
                            <option value="offer">Offer</option>
                            <option value="ongoing">Ongoing</option>
                            <option value="stale">Stale</option>
                        </Selector>
                        <SearchContainer >
                            <SearchIcon src={search} alt=""/>
                            <SearchBar type="text" placeholder="Search for anything..." value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}}/>
                        </SearchContainer>
                    </Controls>
                
                    <Controls tw="flex-row">
                        <Button tw="border-none focus:outline-none" name="Export" onClick={() => {jobsToCSV(jobs)}}>
                            <ExportIcon src={download} alt=""/>
                        </Button>
                        <Button tw="border-none focus:outline-none" name="Add Job" onClick={openAddEntry}>
                            <AddIcon src={add} alt="" />
                        </Button>
                        <Modal isOpen = {showAddEntry} style = {OverrideModalStyles} >
                            <NewEntryModal
                            CloseModal={closeAddEntry}
                            fetchAgain={fetchAgain}
                            />
                        </Modal>
                    </Controls>
                </ControlsContainer>
                
                <FadeIn transitionDuration={2500}>
                    {/* There was an error where if you'd search for jobs and didn't have any matches, it'd render the 'looks like you don't have any jobs yet' banner */}
                    <JobsTable>
                        {(currentJobs !== null && jobs.length !== 0) ? currentJobs.map((job) => (
                        <JobRow 
                            key={job.row_id}
                            RowID = {job.row_id}
                            DateAndTime = {job.date}
                            CompanyName = {job.company}
                            JobTitle = {job.title}
                            JobURL = {job.url}
                            Notes = {job.notes}
                            Status = {job.status}
                            deleteOnClient={deleteOnClient}
                            overwriteRowOnClient={overwriteRowOnClient}
                        /> )) : NoJobsBanner }
                    </JobsTable>
                    
                    <JobPagination 
                        jobsPerPage={jobsPerPage} 
                        totalJobs={searchResults.length} 
                        paginate={paginate} 
                        indexOfFirstJob={indexOfFirstJob} 
                        indexOfLastJob={indexOfLastJob}
                    /> 
                </FadeIn>
                
            </Content>        
            <hr></hr>
            {(currentJobs !== null && currentJobs.length !== 0) ? ClearBit : null }
        </Container>
    );
};