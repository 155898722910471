import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import quick from "../../app-images/time.svg"
import smart from "../../app-images/brain.svg"
import organized from "../../app-images/organization.svg"
import tracker from "../../app-images/list.svg"

/* Setup the styling using tailwind macro */
const Container = tw.div`relative`;
const Content = tw.div`flex flex-col xl:flex-row-reverse items-center justify-around mx-auto my-8 md:my-16`
const FeaturesContainer = styled.div`${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg`}`;
const HeadingWrapper = tw.div`p-4 text-center`
const Heading = tw.h2`text-4xl lg:text-5xl font-black tracking-wide text-center w-full`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl text-base lg:text-lg text-center`;
const Column = styled.div`${tw`max-w-md lg:max-w-sm`}`;

/* Card styling for each feature */
const Card = styled.div`${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-4`}
  .imageContainer { ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img { ${tw`w-8 h-8`} }
  }
  .textContainer { ${tw`sm:ml-4 mt-4 sm:mt-2`} }
  .title { ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`} }
  .description { ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`} }`;

/* Pass down the properties to this features component */
export default (
  { 
  cards = null, 
  heading = (<>Tailored for <span tw="text-primary-500">Job Hunters.</span></>), 
  description = "Our job tracking tool is setup with key features to help you bootstrap your career search." 
  }
) => {
  cards = [
    {
      imageSrc: quick,
      title: "Quick",
      description: "Add jobs on the fly! No need to manually track the details, just toggle your extension and add the job in seconds."
    },
    {
      imageSrc: smart,
      title: "Smart",
      description: "The extension analyzes the job posting and attempts to automatically extract important information such as the company name and job title."
    },
    {
      imageSrc: organized,
      title: "Organized",
      description: "All of your job applications are stored to your profile, in one place, allowing for organized management."
    },
    {
      imageSrc: tracker,
      title: "Structured",
      description: "Waiting to hear back, or haven’t heard back in weeks? Track application status based on your own metrics."
    },
  ];

  return (
    <Container id="Overview">
      <hr tw="mt-4"></hr>
      <Content>
        <HeadingWrapper>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>} 
        </HeadingWrapper>
        <FeaturesContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card>
                <span className="imageContainer">
                  <img src={card.imageSrc} alt="" />
                </span>
                <span className="textContainer">
                  <span className="title">{card.title}</span>
                  <p className="description">{card.description}</p>
                </span>
              </Card>
            </Column>
          ))}
        </FeaturesContainer>
      </Content>
    </Container>
  );
};
