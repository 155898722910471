import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
/* Icon Imports */
import logo from "app-images/logo.svg";
import confused from "app-images/confused.png"

/* All of the element stylings */
const Container = tw.div`relative min-h-screen bg-gray-500 text-white font-medium flex justify-center items-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center items-center flex-1`;
const MainContainer = tw.div`lg:w-3/4 p-6 sm:p-12`;
const LogoImage = tw.img`h-12 mx-auto mb-2`;
const MainContent = tw.div`flex flex-col items-center`;
const ErrorImage = tw.img`max-w-xs h-64 my-4`;

/* Login Page Component Itself */
export default ({
  title = null,
  message = null,
}) => {
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
              <LogoImage src={logo} />
              <h1 tw="text-3xl font-extrabold text-center text-primary-500 mb-1">{!title ? "THIS IS AN ERROR." : title}</h1>
              <hr tw=""></hr>
            <MainContent>
              <ErrorImage src={confused}></ErrorImage>        
              <p tw="text-xl font-semibold text-center text-secondary-500 my-4 w-full tracking-wider whitespace-pre-line">{!message ? "Looks like something went wrong. \n Please try again in a couple of seconds!" : message }</p>
              <a href="/"><button tw="bg-primary-500 hover:bg-primary-400 text-white font-bold py-2 px-4 border-b-4 border-primary-700 hover:border-primary-500 rounded">Start Over</button></a>      
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  ); 
};