import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "app-components/LandingPage/Hero.js";
import Overview from "app-components/LandingPage/Overview.js"
import Usage from "app-components/LandingPage/Usage.js"
import GetStarted from "app-components/LandingPage/GetStarted.js"
import Footer from "app-components/Footer.js";
import RequireNoAuthentication from "./RequireNoAuthentication.js";


export default () => {
  return (
    <AnimationRevealPage>
      <Hero/>
      <Overview/>
      <Usage />
      {/* If someone is logged in don't let them see the getStarted container */}
      <RequireNoAuthentication Component={GetStarted} returnNullOnChange={true}/>
      <Footer />
    </AnimationRevealPage>
  );
};
