import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useState } from "react";
import FadeIn from 'react-fade-in';
import MoonLoader from "react-spinners/MoonLoader";
import LoadingOverlay from 'react-loading-overlay';
import edit from "../../app-images/edit.svg"
import exit from "../../app-images/exit.svg"

/* Modal stuff */
const Icon = tw.img`max-w-xs h-8 my-2`
const ModalContainer = tw.div`flex flex-col items-center justify-center text-secondary-500 p-2 w-full my-2 bg-transparent`
const ModalHeader = tw.div`flex flex-row items-center justify-between w-full border-b mb-2`
const ModalTitle = tw.h1`font-semibold tracking-wide text-center text-lg w-full`
const ModalNotesContainer = tw.div`whitespace-pre-line rounded-lg w-full border rounded-sm p-4 h-auto bg-gray-100`
const ModalNotesEditContainer = tw.textarea`whitespace-pre-line rounded-lg w-full border rounded-sm p-2 h-32 bg-gray-200 text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`
const ModalFooter = tw.div`flex flex-row items-center justify-end w-full mt-2`
const ModalButton = tw.button`border-none focus:outline-none`
const ModalCancelButton = tw.button`bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-lg shadow mx-1`;
const ModalSaveButton = tw.button`bg-orange-500 hover:bg-orange-700 text-white font-semibold py-2 px-4 border border-orange-500 rounded-lg mx-1 shadow`;

/* Pass down the properties to this features component */
export default ({
    CompanyName = null,
    JobTitle = null,
    Notes = null,
    CloseModal = null,
    updateRow = null /* updateRow is a function passed from the parent component that accepts options to update a row's values */
}) => {
    /* Local state management */
    const [modalEditState, setModalEditState] = useState(false);
    const [newNotesValue, setNewNotesValue] = useState(Notes);
    const [loading, setLoading] = useState(false);

    let openEditModal = () => { setModalEditState(true) };
    let closeEditModal = () => { setModalEditState(false) };
    let submitChanges = () => {
        setLoading(true)
        /* Add an 1 second delay to simulate loading */
        setTimeout(() => {
            updateRow( {newNotes : newNotesValue} );
            setLoading(false);
            setModalEditState(false);
        }, 1000)
    };

    const EditButtons = 
        <ModalFooter>
            <ModalCancelButton onClick={closeEditModal}>Cancel</ModalCancelButton>
            <ModalSaveButton onClick={submitChanges}>Save</ModalSaveButton>
        </ModalFooter>;

    return (
        <LoadingOverlay 
        active = {loading}
        spinner = { <MoonLoader size={70} color={"#ed8936"}/> }
        styles={{
            overlay: (base) => ({
              ...base,
              backgroundColor: 'currentColor'
            })
          }}
        >
            <ModalContainer>
                <ModalHeader>
                    <ModalButton onClick={openEditModal}><Icon src={edit} alt="" tw="h-5 m-4"/></ModalButton>
                    <ModalTitle>Notes for <span tw="font-extrabold">{JobTitle === "" ? "Unknown Role" : JobTitle}</span> at <span tw="font-extrabold">{CompanyName === "" ? "Unknown Company" : CompanyName}</span>
                    </ModalTitle>
                    <ModalButton onClick={CloseModal}><Icon src={exit} alt="" tw="h-4 m-4"/></ModalButton>
                </ModalHeader>
                {modalEditState ? null : <FadeIn tw="w-full"><ModalNotesContainer>{Notes !== "" ? Notes : "Looks like you don't have any notes. Click edit to add some!"}</ModalNotesContainer></FadeIn> }
                {modalEditState ? <FadeIn tw="w-full"><ModalNotesEditContainer onChange={(e) => {setNewNotesValue(e.target.value) }}>{Notes}</ModalNotesEditContainer></FadeIn> : null }
                {modalEditState ? EditButtons : null }
            </ModalContainer>
        </LoadingOverlay>
    );
};
