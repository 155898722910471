import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
/* Image imports */
import logo from "../app-images/logo.svg";
import linkedin from "../app-images/linkedin.svg";
import github from "../app-images/github.svg";
import portfolio from "../app-images/portfolio.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-2 py-4`;
const LogoContainer = tw.div`flex items-center justify-center`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-extrabold text-secondary-500 text-left`;
const CompanyDescription = tw.p`font-semibold text-sm mt-2`;
const SocialLinksContainer = tw.div`my-3 flex flex-row items-stretch justify-around`;
const SocialImg = tw.img`max-w-xs h-8 mx-1`

export default () => {
  let donate = 
  <> 
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
      <input type="hidden" name="cmd" value="_donations" />
      <input type="hidden" name="business" value="4L5ZP4RV8NSNL" />
      <input type="hidden" name="currency_code" value="USD" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      {/* <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" /> */}
    </form>
  </>;
  let year = new Date().getFullYear();
  let version = "1.0.1";

  return (
    <Container>
      <div tw="flex flex-col md:flex-row items-stretch justify-between p-5 w-full">
        
        <div tw="flex flex-col items-start justify-center w-full md:w-5/12 my-1">
          <h1 tw="text-base font-bold border-b-2 w-full tracking-wide">THE MISSION</h1>
          <CompanyDescription>trackjobs4.me is a web application that aims to streamline the job hunt process by providing users a simple and easy to use platform to add and track jobs they're applying to.</CompanyDescription>
          <CompanyDescription>Feel free to connect with me, the developer, through any of the social media accounts below!</CompanyDescription>
          <SocialLinksContainer>
            <a href="https://linkedin.com/in/zain-shafique" target="_blank" rel="noopener noreferrer"><SocialImg src={linkedin}></SocialImg></a>
            <a href="https://github.com/zshafiqu" target="_blank" rel="noopener noreferrer"><SocialImg src={github}></SocialImg></a>
            <a href="https://zainshafique.com" target="_blank" rel="noopener noreferrer"><SocialImg src={portfolio}></SocialImg></a>
          </SocialLinksContainer>
          {donate}
        </div>

        <div tw="flex flex-col items-start justify-center w-full md:w-6/12 my-1">
          <h1 tw="text-base font-bold border-b-2 w-full tracking-wide">CONTACT</h1>
          <form tw="my-2 w-full" action="https://formspree.io/xnqoaynb" method="POST">
            <input type="text" name="name" id="input-name" placeholder="Your Name" tw="w-full bg-white text-gray-700 border border-gray-400 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-2"/>
            <input type="email" name="email" id="input-email" placeholder="Your Email" tw="w-full bg-white text-gray-700 border border-gray-400 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-2"/>
            <textarea type="text" name="message" id="input-message" placeholder="Message" tw="w-full bg-white text-gray-700 border border-gray-400 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-2"/>
            <button type="submit" tw="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full">Send Message</button>        
          </form>
        </div>
        
      </div>

      <div tw="flex flex-row items-center justify-center px-5 pt-5 w-full border-t-2">
        <div tw="flex flex-col items-center justify-center">
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>TRACKJOBS4.ME</LogoText>
          </LogoContainer>
          <h1 tw="text-sm font-medium tracking-wide">© {year} trackjobs4.me</h1>    
          <h1 tw="text-sm font-bold mt-1">version {version}</h1>    
        </div>
      </div>

    </Container>
  );
};
