import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import FadeIn from "react-fade-in";

/* Setup the styling using tailwind macro */
const Container = tw.div`bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-2 flex flex-col`;
const Title = tw.strong`font-bold`;
const Message = tw.span`block sm:inline whitespace-pre-line`;
const CloseContainer = tw.span`absolute top-0 bottom-0 right-0 px-4 py-3`;
const CloseSVG = tw.svg`fill-current h-6 w-6 text-red-500`;

/* Pass down the properties to this features component */
export default ({
  title = null,
  message = null,
  visibility = null,
  setVisibility = null,
}) => {
  if (visibility === true) {
    return (
      <FadeIn transitionDuration={1000}>
        <Container>
            <Title>{title ? title : "Whoops!"}</Title>
            <Message> {message ? message : "Looks like there was an error. Please try again in a few seconds!"}</Message>
            <CloseContainer>
                <CloseSVG role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={() => {setVisibility(false)}}>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                </CloseSVG>
            </CloseContainer>
        </Container>
      </FadeIn>
    ); 
  } else {
    return null;
  }
};
