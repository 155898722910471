import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useEffect } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import LandingPage from "./app-pages/LandingPage.js";
import Dashboard from "./app-pages/Dashboard.js";
import Login from "./app-pages/Login.js";
import SignUp from "./app-pages/SignUp.js";
import ForgotPassword from "./app-pages/ForgotPassword.js";
import ResetPassword from "./app-pages/ResetPassword.js";
import Account from "./app-pages/Account.js";
import Feedback from "./app-pages/Feedback.js";
import Error from "./app-pages/ErrorPage.js"
import RequireAuthentication from "./app-pages/RequireAuthentication.js"
import RequireNoAuthentication from "./app-pages/RequireNoAuthentication.js"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga'; 

export default function App() {

  /* On mount -> Report current page view to Google Analytics */
  useEffect(() => {
    ReactGA.initialize('UA-153873014-2');
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (

    <Router>
      <Switch>

        <Route exact path="/">
          <LandingPage />
        </Route>

        <Route exact path="/sign-in">
          <RequireNoAuthentication Component={Login}/>
        </Route>

        <Route exact path="/sign-up">
          <RequireNoAuthentication Component={SignUp}/>
        </Route>

        <Route exact path="/forgot-password">
          <RequireNoAuthentication Component={ForgotPassword}/>
        </Route>

        <Route exact path="/reset-password">
          <RequireNoAuthentication Component={ResetPassword}/>
        </Route>

        <Route exact path="/my-jobs">
          <RequireAuthentication Component={Dashboard}/>
        </Route>

        <Route exact path="/my-account">
          <RequireAuthentication Component={Account}/>
        </Route>

        <Route exact path="/submit-feedback">
          <RequireAuthentication Component={Feedback}/>
        </Route>

        <Route path="*">
          <Error title={"404. THIS IS AN ERROR."} message={"Looks like you requested a page that doesn't exist."}/>
        </Route>

      </Switch>
    </Router>

  );
}