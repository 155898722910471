import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import Header from "../Nav.js";
/* Import images */
import chrome from "../../app-images/chrome_whitebg.png";
import firefox from "../../app-images/firefox_nobg.png"
import temp from "../../app-images/dashboard.png"

const Container = tw.div`relative`;
const Content = tw.div`flex flex-col xl:flex-row flex-shrink items-center justify-center text-center mx-auto my-8 md:my-16 `
const Heading = tw.h1`font-bold text-4xl lg:text-5xl leading-tight mb-2`;
const Paragraph = tw.p`text-base lg:text-lg mb-2 font-medium`;
const GetContainer = tw.div`flex flex-col md:flex-row justify-center items-center`
const HeroImage = tw.div`flex flex-col justify-center items-center my-10`
const MessageContainer = tw.div`flex flex-col justify-center`;
const ImageContainer = tw.div`flex flex-col justify-center`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <hr tw="mt-4"></hr>
        <Content>
          <MessageContainer>
            <Heading tw="font-extrabold mb-2">No more spreadsheets.</Heading>
            <Heading tw="font-extrabold text-3xl lg:text-4xl text-orange-500">All of your job applications, in one place.</Heading>
            <Paragraph>Easily add job postings to your profile in a matter of seconds <br></br> and manage them all on our free and easy to use platform</Paragraph>
            <GetContainer>
              <a href="https://chrome.google.com/webstore/detail/trackjobs4me/fdepaacddefkhpkkebcphgjmdobpmhcm" target="_blank" rel="noopener noreferrer">
                <img src={chrome} alt="" tw="max-w-xs m-1"></img>
              </a>
              <a href="https://addons.mozilla.org/en-US/firefox/addon/trackjobs4-me/" target="_blank" rel="noopener noreferrer">
                <img src={firefox} alt="" tw="max-w-xs h-16 m-1"></img>
              </a>
            </GetContainer>
          </MessageContainer>
          <ImageContainer>
            <HeroImage>
              <img src={temp} alt="" tw="max-w-xs md:max-w-xl shadow-lg md:shadow-2xl mx-4 rounded-lg"></img>
            </HeroImage>
          </ImageContainer>
        </Content>
      </Container>
    </>
  );
};
